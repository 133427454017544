import { React, useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import ViewDetailButton from "./ViewDetailButton";
import MiniBordaStackedChart from "./MiniBordaStackedChart.js";
import MiniScatterChart from "./MiniScatterChart.js";
import MiniTieRatioChart from "./MiniTieRatioChart";
import { Icon } from "@iconify/react";
import "./Layer1Card.css";

const Layer1Card = ({
  descriptiveData,
  onUpdateActiveLayer,
  onHide,
  handleUpload,
}) => {
  const cardStyle1 = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.08)", // Reduced and softened shadow
    transition: "0.3s",
    borderRadius: "6px",
    border: "none",
  };

  const inputInfo = descriptiveData ? descriptiveData.input_info : null;
  const problemTypes =
    inputInfo && inputInfo.problem_type
      ? inputInfo.problem_type.split(",")
      : [];
  const preferenceTypes =
    inputInfo && inputInfo.preference_type
      ? inputInfo.preference_type.split(",")
      : [];
  const level1Info = descriptiveData ? descriptiveData.level1_info : null;
  const [activeTab, setActiveTab] = useState("proposer");
  const [activeSide, setActiveSide] = useState([]);
  const [inactiveSide, setInactiveSide] = useState([]);

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  const [max_d, setMaxD] = useState(0.7);
  const [currentClusterDict, setCurrentClusterDict] = useState(null);

  useEffect(() => {
    if (
      level1Info &&
      level1Info[activeTab] &&
      level1Info[activeTab].hierarchical_clustering
    ) {
      const thresholds = level1Info[activeTab].hierarchical_clustering[0];
      const clusterDicts = level1Info[activeTab].hierarchical_clustering[1];
      const thresholdIndex = thresholds.findIndex((threshold, index) => {
        return (
          max_d >= threshold &&
          (index === thresholds.length - 1 || max_d < thresholds[index + 1])
        );
      });
      if (thresholdIndex !== -1) {
        setCurrentClusterDict(clusterDicts[thresholdIndex]);
      }
    }
    console.log(currentClusterDict);
    if (activeTab === "proposer") {
      setActiveSide("A");
      setInactiveSide("B");
    } else if (activeTab === "proposee") {
      setActiveSide("B");
      setInactiveSide("A");
    }
  }, [level1Info, max_d, activeTab]);

  return (
    descriptiveData && (
      <>
        <Container fluid style={{ width: " 95%" }}>
          <Row style={{ marginTop: "1rem", marginBottom: "2rem" }}>
            <Col md={4}>
              <Button
                onClick={onHide}
                variant="secondary"
                style={{ padding: "1rem", width: "8rem" }}
              >
                Close
              </Button>
            </Col>
            <Col
              md={4}
              style={{
                display: "flex",
                marginLeft: "-20px",
                marginRight: "20px",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h2>Explainable Data</h2>
            </Col>
            <Col md={4} style={{ textAlign: "end" }}>
              <Button
                onClick={handleUpload}
                variant="dark"
                style={{ padding: "1rem", width: "18rem" }}
              >
                Continue: Recommend Solutions{" "}
                <Icon icon="bytesize:arrow-right" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={1}>
              <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id="justify-tab-example"
                className="mb-3"
                justify
              >
                <Tab
                  eventKey="proposer"
                  title={<span className="rotate-text">Side A</span>}
                  className={activeTab === "proposer" ? "active" : ""}
                ></Tab>

                <Tab
                  eventKey="proposee"
                  title={<span className="rotate-text">Side B</span>}
                  className={activeTab === "proposee" ? "active" : ""}
                ></Tab>
              </Tabs>
            </Col>

            <Col xs={12} md={11}>
              <div className="tab-wrapper">
                <Row>
                  <Col md={4} style={{ textAlign: "center" }}>
                    <Card className="mb-4" style={cardStyle1}>
                      <br></br>
                      <h2>{problemTypes[0]}</h2>
                      <Card.Subtitle className="mb-2 text-muted">
                        Market Type
                      </Card.Subtitle>
                      <br></br>
                    </Card>
                  </Col>
                  <Col md={4} style={{ textAlign: "center" }}>
                    <Card className="mb-4" style={cardStyle1}>
                      <br></br>
                      <h2>{problemTypes[1]}</h2>
                      <Card.Subtitle className="mb-2 text-muted">
                        Matching Type
                      </Card.Subtitle>
                      <br></br>
                    </Card>
                  </Col>
                  <Col md={4} style={{ textAlign: "center" }}>
                    <Card className="mb-4" style={cardStyle1}>
                      <br></br>
                      <h2>{level1Info.proposee.n}</h2>
                      <Card.Subtitle className="mb-2 text-muted">
                        Number of Alternatives
                      </Card.Subtitle>
                      <br></br>
                    </Card>
                  </Col>
                  {/* <Col md={3} style={{ textAlign: "center" }}>
                    <Card className="mb-4" style={cardStyle1}>
                      <br></br>
                      <h2>12
                        %</h2>
                      <Card.Subtitle className="mb-2 text-muted">
                        % of Incompleteness
                      </Card.Subtitle>
                      <br></br>
                    </Card>
                  </Col> */}
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <Card className="mb-4 hover-card" style={cardStyle1}>
                      <Card.Body>
                        <Card.Text>
                          <div
                            style={{ position: "relative", height: "300px" }}
                          >
                            {level1Info && (
                              <MiniBordaStackedChart
                                data={
                                  activeTab === "proposer"
                                    ? level1Info.proposer.borda_score_stack
                                    : level1Info.proposee.borda_score_stack
                                }
                                currentTab={activeTab}
                              />
                            )}
                          </div>
                        </Card.Text>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Card.Title className="hover-title">
                            <h3>Popularity</h3>
                          </Card.Title>
                        </div>
                        <div style={{ position: "relative", height: "100px" }}>
                          <Card.Text
                            style={{
                              display: "flex",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Card.Subtitle className="mb-2 text-muted">
                              Indicates how desirable Side {activeSide}'s
                              alternatives are from Side {inactiveSide}'s
                              viewpoint
                            </Card.Subtitle>
                          </Card.Text>
                          <ViewDetailButton
                            name="Popularity"
                            onClick={() => onUpdateActiveLayer("popularity")}
                          ></ViewDetailButton>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} md={4}>
                    <Card className="mb-4 hover-card" style={cardStyle1}>
                      <Card.Body>
                        <Card.Text>
                          <div
                            style={{ position: "relative", height: "300px" }}
                          >
                            {level1Info && currentClusterDict && (
                              <MiniScatterChart
                                inputData={level1Info[activeTab].pca}
                                clusterDict={currentClusterDict}
                              />
                            )}
                          </div>
                        </Card.Text>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Card.Title className="hover-title">
                            <h3>Similarity</h3>
                          </Card.Title>
                        </div>

                        <div style={{ position: "relative", height: "100px" }}>
                          <Card.Text
                            style={{
                              display: "flex",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Card.Subtitle className="mb-2 text-muted">
                              Measure the similarity of alternative's preference
                              between each other{" "}
                            </Card.Subtitle>
                          </Card.Text>
                          <ViewDetailButton
                            name="similarity"
                            onClick={() => onUpdateActiveLayer("similarity")}
                          ></ViewDetailButton>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} md={4}>
                    <Card className="mb-4 hover-card" style={cardStyle1}>
                      <Card.Body>
                        <Card.Text>
                          <div
                            style={{ position: "relative", height: "300px" }}
                          >
                            {level1Info && (
                              <MiniTieRatioChart
                                inputData={
                                  activeTab === "proposer"
                                    ? level1Info.proposer.tie_ratio_group
                                    : level1Info.proposee.tie_ratio_group
                                }
                                tieRatio={
                                  activeTab === "proposer"
                                    ? level1Info.proposer.tie_ratio
                                    : level1Info.proposee.tie_ratio
                                }
                                tabChange={activeTab === "proposer" ? "A" : "B"}
                              />
                            )}
                          </div>
                        </Card.Text>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Card.Title className="hover-title">
                            <h3>Ties</h3>
                          </Card.Title>
                        </div>

                        <div style={{ position: "relative", height: "100px" }}>
                          <Card.Text
                            style={{
                              display: "flex",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Card.Subtitle className="mb-2 text-muted">
                              Help you interpret the ties in your preference
                              data
                            </Card.Subtitle>
                          </Card.Text>
                          <ViewDetailButton
                            name="indifference"
                            onClick={() => onUpdateActiveLayer("indifference")}
                          ></ViewDetailButton>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    )
  );
};

export default Layer1Card;
