import React, { useState, useEffect, useCallback } from "react";
import ReactFlow, {
  Controls,
  Background,
  Position,
  MarkerType,
  MiniMap,
} from "reactflow";
import { FaCircle } from "react-icons/fa";
import "reactflow/dist/style.css";
import "./ReactFlow.css";

function Flow2({
  matchData,
  properties,
  bp,
  envy,
  match,
  envyVis,
  setEnvyVis,
  envyInfo,
  setEnvyInfo,
  selectInfo,
  setSelectInfo,
}) {
  // let envyVis = Array(matchData.metaData[1] + matchData.metaData[2]).fill(false);

  const [poVis, setPoVis] = useState(
    Array(properties?.po.cycleList.length).fill(true)
  );

  const [poVisMap, setPoVisMap] = useState(
    Array(matchData.metaData[1] + matchData.metaData[2]).fill(null)
  );

  const [trackClick, setTrackClick] = useState(false);
  // let envyInfo = "Click on an node to see that agent's envy"

  const [showEnvy, setShowEnvy] = useState("false");
  const onNodeClick = (event, node) => {
    setTrackClick(!trackClick);
    if (node.id.charAt(0).valueOf() == "m") {
      console.log("MAN");
      envyVis[parseInt(node.id.substring(1)) - 1] =
        !envyVis[parseInt(node.id.substring(1)) - 1];
      selectInfo[parseInt(node.id.substring(1)) - 1] =
        !selectInfo[parseInt(node.id.substring(1)) - 1];
      setEnvyVis(envyVis);
      setSelectInfo(selectInfo);
      // setEnvyInfo("Loading...")
      if (envyVis[parseInt(node.id.substring(1)) - 1] == false) {
        setEnvyInfo(
          "Agent " +
            parseInt(node.id.substring(1)).toString() +
            " " +
            properties.envy.envyDict[node.id]
        );
        // setEnvyInfo(node.id)
        console.log(envyInfo);
      }
    } else {
      console.log("WOMAN");
      envyVis[parseInt(node.id.substring(1)) + matchData.metaData[1] - 1] =
        !envyVis[parseInt(node.id.substring(1)) + matchData.metaData[1] - 1];
      setEnvyVis(envyVis);
      selectInfo[parseInt(node.id.substring(1)) + matchData.metaData[1] - 1] =
        !selectInfo[parseInt(node.id.substring(1)) + matchData.metaData[1] - 1];
      setSelectInfo(selectInfo);
      if (
        envyVis[parseInt(node.id.substring(1)) + matchData.metaData[1] - 1] ==
        false
      ) {
        setEnvyInfo(
          "Agent " +
            String.fromCharCode(parseInt(node.id.substring(1)) + 64) +
            " " +
            properties.envy.envyDict[node.id]
        );
        // setEnvyInfo(node.id)
        console.log(envyInfo);
      }
    }
    console.log("click node", node);
    console.log("click node", envyVis);
  };
  const onEdgeClick = (event, edge) => console.log("click edge", edge);

  const envyVisCheck = (key) => {
    // console.log("AFDFAD")
    if (key.charAt(0).valueOf() == "m") {
      // console.log(key, parseInt(key.substring(1))-1)
      // console.log(envyVis)
      return envyVis[parseInt(key.substring(1)) - 1];
    } else {
      // console.log(envyVis[parseInt(key.substring(1)) + matchData.metaData[1] - 1])
      return envyVis[parseInt(key.substring(1)) + matchData.metaData[1] - 1];
    }
  };

  useEffect(() => {
    // setShowEnvy(envy);
    console.log(envyVis);
  }, [trackClick, envyVis, onNodeClick, setEnvyVis, envy, bp, match]);

  useEffect(() => {
    if (typeof properties?.po !== "undefined") {
      let arr = poVisMap;
      for (let i = 0; i < properties.po.cycleList.length; i++) {
        console.log("HERE 4");
        for (let j = 0; j < properties.po.cycleList[i].length - 1; j++) {
          if (
            properties.po.cycleList[i][j] instanceof String ||
            typeof properties.po.cycleList[i][j] === "string"
          ) {
            arr[properties.po.cycleList[i][j].charCodeAt(0) - 65] = i;
            arr[matchData.metaData[1] + properties.po.cycleList[i][j + 1]] = i;
          } else {
            arr[properties.po.cycleList[i][j + 1].charCodeAt(0) - 65] = i;
            arr[matchData.metaData[1] + properties.po.cycleList[i][j]] = i;
          }
        }
      }
      setPoVis(arr);
    }
  }, []);

  console.log(matchData);
  console.log(properties);
  // console.log(showEnvy)

  var smartNode = [];
  var smartEdge = [];

  // console.log("yes")

  let mParentX = 25 + 20 - 50 / Math.sin(Math.PI / matchData.metaData[1]) - 50;
  let mParentY = 25 + 500 - 50 / Math.sin(Math.PI / matchData.metaData[1]) - 50;
  let wParentX =
    25 +
    400 -
    50 / Math.sin(Math.PI / matchData.metaData[1]) +
    100 / Math.sin(Math.PI / matchData.metaData[1]) -
    50;
  let wParentY = 25 + 650 - 50 / Math.sin(Math.PI / matchData.metaData[1]) - 50;

  //   let showAgentEnvy = new Array(matchData.metaData[1]+matchData.metaData[2]).fill(false);
  const showAgentEnvy = useCallback(console.log("hello"));

  smartNode.push({
    id: "7",
    type: "default",
    className: "annotation",
    draggable: true,
    data: {
      label: (
        <>
          <strong>Envy Details:</strong>
          {"\n"} {envyInfo}
        </>
      ),
    },
    style: {
      fontSize: "16px",
      background: "rgb(235,240,246)",
    },
    draggable: true,
    selectable: false,
    position: {
      x:
        400 -
        50 / Math.sin(Math.PI / matchData.metaData[1]) +
        100 / Math.sin(Math.PI / matchData.metaData[1]) +
        (50 / Math.sin(Math.PI / matchData.metaData[1]) + 50) -
        170,
      y: 25 + 500 - 50 / Math.sin(Math.PI / matchData.metaData[1]) - 50,
    },
  });

  smartNode.push({
    id: "8",
    type: "default",
    className: "key",
    draggable: true,
    data: {
      label: (
        <>
          <strong>Key:</strong>
          <br />
          <p style={{ color: "#f56342", display: "inline" }}>○</p> - the agent
          weakly prefers their blocking pair partner to their current partner{" "}
          <br />
          <p style={{ color: "#f56342", display: "inline" }}>●</p> - the agent
          strictly prefers their blocking pair partner to their current partner
        </>
      ),
    },
    style: {
      fontSize: "16px",
    },
    draggable: true,
    selectable: false,
    hidden: !bp,
    position: {
      x: mParentX,
      y:
        mParentY +
        2 * (50 / Math.sin(Math.PI / matchData.metaData[1]) + 50) +
        50,
    },
  });

  smartNode.push({
    id: "m-parent",
    type: "default",
    data: {
      label: null,
    },
    className: "circle",
    selectable: false,
    draggable: false,
    style: {
      background: "#2B6CB0",
      opacity: "10%",
      width: 2 * (50 / Math.sin(Math.PI / matchData.metaData[1]) + 50),
      height: 2 * (50 / Math.sin(Math.PI / matchData.metaData[1]) + 50),
    },
    position: { x: mParentX, y: mParentY },
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
  });

  smartNode.push({
    id: "w-parent",
    type: "default",
    data: {
      label: null,
    },
    className: "circle",
    selectable: false,
    draggable: false,
    style: {
      background: "#f2570a",
      opacity: "10%",
      width: 2 * (50 / Math.sin(Math.PI / matchData.metaData[1]) + 50),
      height: 2 * (50 / Math.sin(Math.PI / matchData.metaData[1]) + 50),
    },
    position: { x: wParentX, y: wParentY },
    sourcePosition: Position.Left,
    targetPosition: Position.Left,
  });

  for (let i = 0; i < matchData.metaData[1]; i++) {
    smartNode.push({
      id: "m" + (i + 1),
      parentNode: "m-parent",
      type: "default",
      data: {
        label: i + 1,
      },
      className: "circle",
      style: {
        background: "#2B6CB0",
        color: selectInfo[i] ? "#00ffff" : "white",
        width: 50,
        height: 50,
        fontWeight: selectInfo[i] ? 900 : 500,
      },
      // selected: selectInfo[i],
      position: {
        x:
          (50 / Math.sin(Math.PI / matchData.metaData[1])) *
            Math.cos((2 * Math.PI * i) / matchData.metaData[1] - Math.PI / 2) +
          50 / Math.sin(Math.PI / matchData.metaData[1]) +
          25,
        y:
          (50 / Math.sin(Math.PI / matchData.metaData[1])) *
            Math.sin((2 * Math.PI * i) / matchData.metaData[1] - Math.PI / 2) +
          50 / Math.sin(Math.PI / matchData.metaData[1]) +
          25,
      },
      sourcePosition: Position.Right,
      //   targetPosition: Position.Left,
      targetPosition: Position.Right,
    });
    smartEdge.push({
      id: "m" + (i + 1) + "-w" + matchData.matching.men[i].partner,
      source: "m" + (i + 1),
      target: "w" + matchData.matching.men[i].partner,
      type: "default",
      hidden: !match,
    });
  }

  for (let i = 0; i < matchData.metaData[2]; i++) {
    smartNode.push({
      id: "w" + (i + 1),
      parentNode: "w-parent",
      type: "default",
      data: {
        label: String.fromCharCode(65 + i),
      },
      className: "circle",
      style: {
        background: "#f2570a",
        color: selectInfo[i + matchData.metaData[1]] ? "#ffff00" : "white",
        width: 50,
        height: 50,
        fontWeight: selectInfo[i + matchData.metaData[1]] ? 900 : 500,
        // border: '5px solid #ff0000',
      },
      // selected: selectInfo[i + matchData.metaData[1]],
      position: {
        x:
          (50 / Math.sin(Math.PI / matchData.metaData[2])) *
            Math.cos((2 * Math.PI * i) / matchData.metaData[2] - Math.PI / 2) +
          50 / Math.sin(Math.PI / matchData.metaData[2]) +
          25,
        y:
          (50 / Math.sin(Math.PI / matchData.metaData[2])) *
            Math.sin((2 * Math.PI * i) / matchData.metaData[2] - Math.PI / 2) +
          50 / Math.sin(Math.PI / matchData.metaData[2]) +
          25,
      },
      //   sourcePosition: Position.Right,
      sourcePosition: Position.Left,

      targetPosition: Position.Left,
    });
  }

  if (typeof properties?.blockingpairs !== "undefined") {
    for (let i = 0; i < properties.blockingpairs.blockingPairs.length; i++) {
      if (properties.blockingpairs.blockingPairsClass[i][0] === "strict") {
        if (properties.blockingpairs.blockingPairsClass[i][1] === "strict") {
          smartEdge.push({
            id:
              "m" +
              properties.blockingpairs.blockingPairs[i][0] +
              "-w" +
              properties.blockingpairs.blockingPairs[i][1],
            source: "m" + properties.blockingpairs.blockingPairs[i][0],
            target: "w" + properties.blockingpairs.blockingPairs[i][1],
            animated: true,
            type: "default",
            style: { stroke: "#f56342" },
            // MarkerType: 'arrow',
            markerStart: "strict-start",
            markerEnd: "strict-end",
            hidden: !bp,
            label: "Blocking Pair",
            labelStyle: { fill: "#f56342", fontWeight: 700 },
          });
        } else {
          smartEdge.push({
            id:
              "m" +
              properties.blockingpairs.blockingPairs[i][0] +
              "-w" +
              properties.blockingpairs.blockingPairs[i][1],
            source: "m" + properties.blockingpairs.blockingPairs[i][0],
            target: "w" + properties.blockingpairs.blockingPairs[i][1],
            animated: true,
            type: "default",
            style: { stroke: "#f56342" },
            // MarkerType: 'arrow',
            markerStart: "strict-start",
            markerEnd: "tie-end",
            hidden: !bp,
            label: "Blocking Pair",
            labelStyle: { fill: "#f56342", fontWeight: 700 },
          });
        }
      } else {
        if (properties.blockingpairs.blockingPairsClass[i][1] === "strict") {
          smartEdge.push({
            id:
              "m" +
              properties.blockingpairs.blockingPairs[i][0] +
              "-w" +
              properties.blockingpairs.blockingPairs[i][1],
            source: "m" + properties.blockingpairs.blockingPairs[i][0],
            target: "w" + properties.blockingpairs.blockingPairs[i][1],
            animated: true,
            type: "default",
            style: { stroke: "#f56342" },
            // MarkerType: 'arrow',
            markerStart: "tie-start",
            markerEnd: "strict-end",
            hidden: !bp,
            label: "Blocking Pair",
            labelStyle: { fill: "#f56342", fontWeight: 700 },
          });
        } else {
          smartEdge.push({
            id:
              "m" +
              properties.blockingpairs.blockingPairs[i][0] +
              "-w" +
              properties.blockingpairs.blockingPairs[i][1],
            source: "m" + properties.blockingpairs.blockingPairs[i][0],
            target: "w" + properties.blockingpairs.blockingPairs[i][1],
            animated: true,
            type: "default",
            style: { stroke: "#f56342" },
            // MarkerType: 'arrow',
            markerStart: "tie-start",
            markerEnd: "tie-end",
            hidden: !bp,
            label: "Blocking Pair",
            labelStyle: { fill: "#f56342", fontWeight: 700 },
          });
        }
      }
    }
  }

  if (typeof properties?.envy !== "undefined") {
    for (let i = 0; i < properties.envy.envyInstances.length; i++) {
      smartEdge.push({
        id:
          properties.envy.envyInstances[i][0] +
          "-" +
          properties.envy.envyInstances[i][1],
        source: properties.envy.envyInstances[i][0],
        target: properties.envy.envyInstances[i][1],
        animated: true,
        type: "straight",
        //   style: { stroke: '#f56342' },
        MarkerType: "arrow",
        hidden: envyVisCheck(properties.envy.envyInstances[i][0]),
        //   label: 'Blocking Pair',
        //   labelStyle: { fill: '#f56342', fontWeight: 700 },
      });
    }
  }

  const poColors = ["#fb8b24", "#0017a2", "#f63e02", "#820263", "#ce1483"];

  if (typeof properties?.po !== "undefined") {
    console.log("TEACHED DFA LDSKFJADLKFJADS");
    for (let i = 0; i < properties.po.cycleList.length; i++) {
      console.log("HERE 2");
      for (let j = 0; j < properties.po.cycleList[i].length - 1; j++) {
        let source = "";
        let target = "";
        if (
          properties.po.cycleList[i][j] instanceof String ||
          typeof properties.po.cycleList[i][j] === "string"
        ) {
          source = "m".concat(
            (properties.po.cycleList[i][j].charCodeAt(0) - 64).toString()
          );
          target = "w".concat(
            (properties.po.cycleList[i][j + 1] + 1).toString()
          );
        } else {
          target = "m".concat(
            (properties.po.cycleList[i][j + 1].charCodeAt(0) - 64).toString()
          );
          source = "w".concat((properties.po.cycleList[i][j] + 1).toString());
        }
        console.log(source);
        console.log(target);
        smartEdge.push({
          id: source + "-" + target,
          source: source,
          target: target,
          animated: true,
          hidden: !envy,
          type: "straight",
          style: { stroke: poColors[i] },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 50,
            height: 50,
            color: poColors[i],
          },
          //   hidden: envyVisCheck(properties.envy.envyInstances[i][0]),
          //   label: 'Blocking Pair',
          //   labelStyle: { fill: '#f56342', fontWeight: 700 },
        });
      }
    }
  }

  console.log(smartNode);
  console.log(smartEdge);

  const minimapStyle = {
    height: 120,
  };

  // const nodes = [
  //   {
  //     id: 'm1',
  //     type: 'target',
  //     data: {
  //       label: 'M1',
  //     },
  //     className: 'circle',
  //     style: {
  //       background: '#2B6CB0',
  //       color: 'white',
  //     },
  //     position: { x: 200, y: 100 },
  //     sourcePosition: Position.Right,
  //     targetPosition: Position.Right,
  //   },
  //   {
  //     id: 'm2',
  //     type: 'target',
  //     data: {
  //       label: 'M2',
  //     },
  //     className: 'circle',
  //     style: {
  //       background: '#2B6CB0',
  //       color: 'white',
  //     },
  //     position: { x: 200, y: 200 },
  //     sourcePosition: Position.Right,
  //     targetPosition: Position.Right,
  //   },
  //   {
  //     id: 'w1',
  //     type: 'target',
  //     data: {
  //       label: 'W1',
  //     },
  //     className: 'circle',
  //     style: {
  //       background: '#f56342',
  //       color: 'white',
  //     },
  //     position: { x: 550, y: 100 },
  //     sourcePosition: Position.Left,
  //     targetPosition: Position.Left,
  //   },
  //   {
  //     id: 'w2',
  //     type: 'target',
  //     data: {
  //       label: 'W2',
  //     },
  //     className: 'circle',
  //     style: {
  //       background: '#f56342',
  //       color: 'white',
  //     },
  //     position: { x: 550, y: 200 },
  //     sourcePosition: Position.Left,
  //     targetPosition: Position.Left,
  //   },
  //   {
  //     id: 'm3',
  //     type: 'target',
  //     data: {
  //       label: 'M3',
  //     },
  //     className: 'circle',
  //     style: {
  //       background: '#2B6CB0',
  //       color: 'white',
  //     },
  //     position: { x: 200, y: 300 },
  //     sourcePosition: Position.Right,
  //     targetPosition: Position.Right,
  //   },
  //   {
  //     id: 'w3',
  //     type: 'target',
  //     data: {
  //       label: 'W3',
  //     },
  //     className: 'circle',
  //     style: {
  //       background: '#f56342',
  //       color: 'white',
  //     },
  //     position: { x: 550, y: 300 },
  //     sourcePosition: Position.Left,
  //     targetPosition: Position.Left,
  //   },
  //   {
  //     id: 'annotation',
  //     type: 'default',
  //     className: 'annotation',
  //     data: {
  //       label: (
  //         <>
  //           Click on any of the agents below to explore any instances of envy (red dotted lines) for that agent.
  //         </>
  //       ),
  //     },
  //     draggable: false,
  //     selectable: false,
  //     position: { x: 10, y: 10 },
  //   },
  // ];

  // const edges = [
  //   { id: '1-3', source: 'm1', target: 'w1', type: 'bevel' },
  //   { id: '2-4', source: 'm2', target: 'w3', type: 'bevel' },
  //   { id: '2-4', source: 'm3', target: 'w2', type: 'bevel' },
  //   { id: '2-4', source: 'w2', target: 'm1', animated: true, type: 'bevel', style: { stroke: '#f56342' }, MarkerType: 'arrow' },
  //   // { id: '2-4', source: '3', target: '5' },
  //   // {
  //   //   id: 'e4-5',
  //   //   source: '2',
  //   //   target: '4',
  //   //   type: 'smoothstep',
  //   //   sourceHandle: 'handle-0',
  //   //   data: {
  //   //     selectIndex: 0,
  //   //   },
  //   //   markerEnd: {
  //   //     type: MarkerType.ArrowClosed,
  //   //   },
  //   // }
  // ];

  return (
    <div style={{ height: "100%" }}>
      <ReactFlow
        nodes={smartNode}
        edges={smartEdge}
        fitView={true}
        nodesDraggable={true}
        onNodeClick={onNodeClick}
        onEdgeClick={onEdgeClick}
      >
        <Background gap={24} />
        <Controls />
        <svg style={{ position: "absolute", top: 0, left: 0 }}>
          <defs>
            <marker
              id="tie-start"
              viewBox="0 0 40 40"
              markerHeight={40}
              markerWidth={40}
              refX={13}
              refY={20}
              color="#ebab34"
            >
              {/* <FaCircle  size={25}/> */}
              <circle
                cx="20"
                cy="20"
                r="10"
                stroke="#f56342"
                stroke-width="2"
                fill="#ffffff"
              />
            </marker>
            <marker
              id="strict-start"
              viewBox="0 0 40 40"
              markerHeight={40}
              markerWidth={40}
              refX={13}
              refY={20}
              color="#ebab34"
            >
              {/* <FaCircle  size={25}/> */}
              <circle
                cx="20"
                cy="20"
                r="10"
                stroke="#f56342"
                stroke-width="2"
                fill="#f56342"
              />
            </marker>
            <marker
              id="tie-end"
              viewBox="0 0 40 40"
              markerHeight={40}
              markerWidth={40}
              refX={27}
              refY={20}
              color="#ebab34"
            >
              {/* <FaCircle  size={25}/> */}
              <circle
                cx="20"
                cy="20"
                r="10"
                stroke="#f56342"
                stroke-width="2"
                fill="#ffffff"
              />
            </marker>
            <marker
              id="strict-end"
              viewBox="0 0 40 40"
              markerHeight={40}
              markerWidth={40}
              refX={27}
              refY={20}
              color="#ebab34"
            >
              {/* <FaCircle  size={25}/> */}
              <circle
                cx="20"
                cy="20"
                r="10"
                stroke="#f56342"
                stroke-width="2"
                fill="#f56342"
              />
            </marker>
          </defs>
        </svg>
      </ReactFlow>
    </div>
  );
}

export default Flow2;
