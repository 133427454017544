import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MatchSummaryCard from "../components/MatchSummaryCard";
import Container from "react-bootstrap/esm/Container";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { API } from "aws-amplify";
import jsonData from "./matchsummary.json";
import jsonDetailData from "./matchdetail.json";
import DescriptiveStatistic from "../components/DescriptiveStatistic";
import { useSelector, useDispatch } from 'react-redux';
import { setDescriptiveData } from '../components/dataSlice';
import { Modal } from "react-bootstrap";
import ReviewProfile from '../components/ReviewProfile';

function MatchSummary() {
  const [data, setData] = useState([{}]);
  const [detailData, setDetailData] = useState([]);

  const [fileName, setFileName] = useState(null);
  const location = useLocation();
  const contentId = location.state?.contentId;
  const demoBool = location.state?.demoBool;

  const descriptiveData = useSelector((state) => state.data.descriptiveData);
  const [showDescriptiveStatistic, setShowDescriptiveStatistic] = useState(false);


  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/compare", { state: { data, demoBool } });
  };


  const codeContent = `
  # PREFERENCE TYPE: toc
  # MATCH TYPE: two-sided
  # MATCH TYPE: one-one
  10,10
  1: 1,5,6,3,2,4,7,{8,9,10}
  2: 7,2,10,1,3,4,5,6,8,9
  3: 3,2,1,4,5,6,7,8,9,10
  4: 1,8,4,2,3,5,{6,7,9,10}
  5: 3,2,1,4,5,6,7,8,9,10
  6: 1,8,4,2,3,5,{6,7,9,10}
  7: {3,2,1,4},5,6,7,8,9,10
  8: {3,2,1,4},5,6,{7,8,9,10}
  9: 1,8,4,2,3,5,6,{7,9,10}
  10: 2,3,4,7,8,1,5,6,9,10
  1: 1,5,6,3,2,4,7,8,9,10
  2: 7,2,10,1,3,4,5,6,{8,9}
  3: 3,2,1,4,5,6,7,8,9,10
  4: 1,8,4,2,3,5,6,7,9,10
  5: 3,2,1,4,5,6,7,{8,9,10}
  6: 1,8,4,2,3,5,6,7,9,10
  7: {3,2,1,4},5,6,7,8,9,10
  8: {3,2,1,4},5,6,{7,8,9,10}
  9: 9,5,6,2,3,4,7,8,1,10
  10: 1,8,4,2,3,5,6,7,9,10
  `;
  const [showProfile, setShowProfile] = useState(false);

   
  const handleProfile = () => {
      setShowProfile(true); 
  };

  const handleButtonClick2 = () => {
    navigate("/prototype", { state: { data } });
  };
  const handleButtonClick3 = () => {
    ;
  };
  const handleButtonClick4 = () => {
    ;
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log("demobool", demoBool);
      if (contentId) {
        try {
          console.log(contentId);
          const apiData = await API.post(
            "matchxplainAPI",
            `/api/match-summary?content_id=${contentId}`
          );
          setData(apiData);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      } else if (demoBool) {
        setData(jsonData);
      } else {
        console.log("No content ID or demo mode provided");
      }
    };

    fetchData();
  }, [contentId, demoBool]);

  useEffect(() => {
    const fetchData = async () => {
      console.log("this is demobool", demoBool);
      if (demoBool) {
        let demoDataArr = [];
        setDetailData(jsonDetailData);

        while (true) {
          if (typeof data !== "undefined") {
            for (let i = 0; i < data.matches?.length; i++) {
              demoDataArr = [...demoDataArr, jsonDetailData];
              // setDetailData(apiDataArr);
              console.log(jsonDetailData.properties.envy.violations);
              // console.log(apiDataArr);
            }
            setDetailData(demoDataArr);
            console.log(demoDataArr);
            return;
          }
        }
      } else {
        let apiDataArr = [];
        console.log("fetch from match");
        while (true) {
          if (typeof data !== "undefined") {
            for (let i = 0; i < data.matches?.length; i++) {
              try {
                console.log("fetch from match-detail");
                const apiData = await API.post(
                  "matchxplainAPI",
                  "/api/match-detail",
                  {
                    body: { data: data.matches[i] },
                  }
                );
                apiDataArr = [...apiDataArr, apiData];
                // setDetailData(apiDataArr);
                console.log(apiData.properties.envy.violations);
                // console.log(apiDataArr);
              } catch (error) {
                console.error("Error fetching data:", error);
              }
            }
            setDetailData(apiDataArr);
            console.log(apiDataArr);
            return;
          }
        }
      }
    };

    fetchData();
  }, [data, demoBool]); // Include demoBool in the dependency array

  return (
    <div>
      <Container>
        <Row>
          <Col lg={4}>
            <h1>Recommended Solutions</h1>
            <p>
              We've generated a couple of solutions for your ordinal preference
              profile. Click "View Solution" on any of the cards to view the
              details of the respective solution.
            </p>
            {/* <Button variant="primary" onClick={handleButtonClick}>
              Compare all results
            </Button> */}
            <p></p>
            {/* <Button variant="outline-dark" onClick={handleButtonClick2}>
              Match Summary Prototype
            </Button> */}
            <Row style={{display:'flex', gap:'1rem'}}>
            <Col>
            <Button onClick={handleProfile} variant="light"  style={{padding:'15px', width:'15rem', fontWeight:'bold'}}>
              Review Preference Profile
          </Button>
          <ReviewProfile 
            showProfile={showProfile} 
            setShowProfile={setShowProfile} 
            codeContent={codeContent} 
          />

            </Col>
            
            <Col>
            <Button
              variant="outline-dark"
              style={{
                padding: '15px',
                width: '15rem',
                fontWeight: 'bold',
              }}
              onClick={() => setShowDescriptiveStatistic(true)}
            >
              Review Explainable Data
            </Button>

            {descriptiveData && showDescriptiveStatistic && (
              <DescriptiveStatistic
                show={showDescriptiveStatistic}
                onHide={() => setShowDescriptiveStatistic(false)}
                
                descriptiveData={descriptiveData}
                
              />
            )}

            </Col>   
              
            </Row>
          </Col>
          <Col lg={8}>
            {/* {typeof detailData === "undefined" ? (
              <p>Loading...</p>
            ) : (
              <p>
                {detailData[1]?.properties?.blockingpairs?.agentsInvolved}kkk
              </p>
            )} */}
            {typeof data === "undefined" ||
            typeof detailData === "undefined" ? (
              <p>Loading...</p>
            ) : (
              <Stack gap={4} key="stack">
                {data.matches?.map((object, i) => {
                  console.log("data", data);
                  if (i == 0) {
                    object.explanation =
                      "This solution uses the Deferred-Acceptance algorithm (also known as stable matching or Gale-Shapley algorithm). The Deferred-Acceptance algorithm guarantees to find a stable matching, meaning that there are no two people on opposite sides of the matching who would both rather have each other than their current partners. This matching is the “side 1-optimal” matching, meaning that agents which are denoted with numbers (agents 1, 2, 3, etc.) are paired with their best-possible stable partner, while agents which are denoted with letters (agents A, B, C, etc.) are paired with their worst-possible stable partner.";
                  } else {
                    object.explanation =
                      "This solution uses the Top-Trading Cycles algorithm (also known as the TTC mechanism). The TTC mechanism guarantees to find a Pareto-optimal allocation, which means that no agent can strictly benefit by changing their partner without hurting another agent (matching them with a partner they prefer less). Here, we are only considering the preferences of “side 1”, meaning that the only preferences that are considered are those of the agents which are denoted with numbers (agents 1, 2, 3, etc.). This means that the agents on “side 2” (denote with letters (agents A, B, C, etc.)) may not be in a Pareto-optimal allocation relative to their preferences.";
                  }
                  return (
                    <MatchSummaryCard
                      matchData={object}
                      key={i}
                      demoBool={demoBool}
                      propertyData={detailData[i]?.properties}
                    />
                  );
                })}
              </Stack>
            )}
          </Col>
        </Row>
      </Container>
      <br />
    </div>
  );
}

export default MatchSummary;
