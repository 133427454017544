import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MiniBordaStackedChart = ({ data }) => {
  
  
  const [scoreToExclude, setScoreToExclude] = useState(Array(data.total.length).fill(0));

  const newLegendClickHandler = function (e, legendItem, legend) {
    const index = legendItem.datasetIndex;
    const ci = legend.chart;

    const excludedKey = index !== null ? ['first', 'second', 'third', 'fourth'][index] : null;

    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
      legendItem.hidden = true;

      const newScoreToExclude = scoreToExclude.map((value, i) => value + data[excludedKey][i]);
      setScoreToExclude(newScoreToExclude);
      setSortedAgents(getSortedAgents(newScoreToExclude));
    } else {
      ci.show(index);
      legendItem.hidden = false;

      const newScoreToExclude = scoreToExclude.map((value, i) => value - data[excludedKey][i]);
      setScoreToExclude(newScoreToExclude);
      setSortedAgents(getSortedAgents(newScoreToExclude));
    }
  };


  const getSortedAgents = (scoreToExclude) => {
    return data.total
      .map((score, index) => ({ x: `Alt ${index + 1}`, y: score - scoreToExclude[index], index }))
      .sort((a, b) => b.y - a.y)
      .slice(0, 5);
  }

  const [sortedAgents, setSortedAgents] = useState(getSortedAgents(scoreToExclude));
  

  useEffect(() => {
    setScoreToExclude(Array(data.total.length).fill(0));
    setSortedAgents(getSortedAgents(Array(data.total.length).fill(0)));
  }, [data]);

  const createSeriesData = (key) => {
    return sortedAgents.map((agent) => data[key][agent.index]);
  };

  const chartData = {
    labels: sortedAgents.map((agent) => agent.x),
    datasets: data.total.length < 4 ? [
      {
        label: '0-50%',
        data: createSeriesData('first'),
        backgroundColor: 'rgba(0, 34, 108, 0.8)',
      },
      {
        label: '50-100%',
        data: createSeriesData('second'),
        backgroundColor: 'rgba(4, 80, 194, 0.8)',
      },
    ] : [
      {
        label: '0-25%',
        data: createSeriesData('first'),
        backgroundColor: 'rgba(0, 34, 108, 0.8)',
      },
      {
        label: '25-50%',
        data: createSeriesData('second'),
        backgroundColor: 'rgba(4, 80, 194, 0.8)',
      },
      {
        label: '50-75%',
        data: createSeriesData('third'),
        backgroundColor: 'rgba(0, 115, 220, 0.7)',
      },
      {
        label: '75-100%',
        data: createSeriesData('fourth'),
        backgroundColor: 'rgba(0, 137, 220, 0.5)',
      },
    ],    
  };

  const chartOptions = {
    indexAxis: 'y', 
    plugins: {
      title: {
        display: true,
        text: 'Popularity Score',
      },
      legend: {
        display: false,
        onClick: newLegendClickHandler,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            const index = context.dataIndex;
            const agentIndex = getSortedAgents(scoreToExclude)[index].index;
            let count = 0;
            let bordaScore = 0;

            switch (dataset.label) {
              case '0-25%':
                count = data.first_count[agentIndex];
                bordaScore = data.first[agentIndex];
                break;
              case '25-50%':
                count = data.second_count[agentIndex];
                bordaScore = data.second[agentIndex];
                break;
              case '50-75%':
                count = data.third_count[agentIndex]; 
                bordaScore = data.third[agentIndex];
                break;
              case '75-100%':
                count = data.fourth_count[agentIndex];
                bordaScore = data.fourth[agentIndex];
                break;
              case '0-50%':
                count = data.first_count[agentIndex];
                bordaScore = data.first[agentIndex];
                break;
              case '50-100%':
                count = data.second_count[agentIndex];
                bordaScore = data.second[agentIndex];
                break;
              default:
                break;
            }

            return `Popularity Scores: ${bordaScore} \n \n Votes Count: ${count}`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        title: {
          display: false,
          text: 'Popularity Scores',
        },
      },
      y: {
        stacked: true,
        min: 0,
      },
    },
  };

  return (
    <div style={{ height: '300px', width: '100%' }}>
      <Bar options={chartOptions} data={chartData} />
    </div>
  );
  
};

export default MiniBordaStackedChart;
