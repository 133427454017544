import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Layer1Card from './Layer1Card';

import PopularityAdvance from './PopularityAdvance';
import DiversityAdvance from './DiversityAdvance';
import IndifferenceAdvance from './IndifferenceAdvance';



function DescriptiveStatistic({ show, onHide, file, descriptiveData, handleUpload, isLoading}) {
    const [activeLayer, setActiveLayer] = useState('layer1card');
  
    const updateActiveLayer = (layer) => {
      setActiveLayer(layer);
    };
    
    return (
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="my-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        
        <Modal.Body>

            
          {show && activeLayer === 'layer1card' &&
            <Layer1Card 
            descriptiveData={descriptiveData} 
            onUpdateActiveLayer={updateActiveLayer}
            onHide={onHide} 
            handleUpload={handleUpload}
          />
          }
          {activeLayer === 'popularity' &&
            <PopularityAdvance
              descriptiveData={descriptiveData} 
              onUpdateActiveLayer={updateActiveLayer} 
              currentLayer={activeLayer}
              handleUpload={handleUpload}
            />
          }
          {activeLayer === 'similarity' &&
            <DiversityAdvance
              descriptiveData={descriptiveData} 
              onUpdateActiveLayer={updateActiveLayer} 
              currentLayer={activeLayer}
              handleUpload={handleUpload}
            />
          }
          {activeLayer === 'indifference' &&
            <IndifferenceAdvance
              descriptiveData={descriptiveData} 
              onUpdateActiveLayer={updateActiveLayer} 
              currentLayer={activeLayer}
              handleUpload={handleUpload}
            />
          }
        </Modal.Body>
  
      </Modal>
    );
  }
  
  export default DescriptiveStatistic;
  