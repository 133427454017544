import React from 'react';
import './ViewDetailButton.css';
import { Icon } from '@iconify/react';

const ViewDetailButton = ({ name, onClick }) => {

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <button className="view-detail-button" onClick={handleClick}>
      View Details
      <Icon className='arrow-icon' icon="gg:arrow-long-right" />
    </button>
    
  );
};

export default ViewDetailButton;
