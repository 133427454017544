import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import FileFormat from "./pages/FileFormat";
import MatchSummary from "./pages/MatchSummary";
import MatchDetailRedesign from "./pages/MatchDetailRedesign";
import ChartJSRadar from "./pages/ChartJSRadar";
import MatchChartPrototype from "./pages/MatchChartPrototype";
import Algorithm from "./pages/Algorithm";
import Learn from "./pages/Learn";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { Provider } from 'react-redux';
import { store } from './components/store';

Amplify.configure(config);

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="file-format" element={<FileFormat />} />
            <Route path="match-summary" element={<MatchSummary />} />
            <Route path="match-detail" element={<MatchDetailRedesign />} />
            <Route path="compare" element={<ChartJSRadar />} />
            <Route path="prototype" element={<MatchChartPrototype />} />
            <Route path='learn' element={<Learn />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
