import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FaCheck, FaArrowLeft, FaRegCircle, FaCircle } from "react-icons/fa";
import Flow2 from "../components/ReactFlowRedesign";
import LargeInstanceFlow from "../components/LargeInstanceFlow";
import { API } from "aws-amplify";
import jsonData from "./matchdetail.json";
import ReviewProfile from '../components/ReviewProfile';
import DescriptiveStatistic from "../components/DescriptiveStatistic";
import { useSelector, useDispatch } from 'react-redux';

function MatchDetail2() {
  const [showProfile, setShowProfile] = useState(false);
  const codeContent = `
  # PREFERENCE TYPE: toc
  # MATCH TYPE: two-sided
  # MATCH TYPE: one-one
  10,10
  1: 1,5,6,3,2,4,7,{8,9,10}
  2: 7,2,10,1,3,4,5,6,8,9
  3: 3,2,1,4,5,6,7,8,9,10
  4: 1,8,4,2,3,5,{6,7,9,10}
  5: 3,2,1,4,5,6,7,8,9,10
  6: 1,8,4,2,3,5,{6,7,9,10}
  7: {3,2,1,4},5,6,7,8,9,10
  8: {3,2,1,4},5,6,{7,8,9,10}
  9: 1,8,4,2,3,5,6,{7,9,10}
  10: 2,3,4,7,8,1,5,6,9,10
  1: 1,5,6,3,2,4,7,8,9,10
  2: 7,2,10,1,3,4,5,6,{8,9}
  3: 3,2,1,4,5,6,7,8,9,10
  4: 1,8,4,2,3,5,6,7,9,10
  5: 3,2,1,4,5,6,7,{8,9,10}
  6: 1,8,4,2,3,5,6,7,9,10
  7: {3,2,1,4},5,6,7,8,9,10
  8: {3,2,1,4},5,6,{7,8,9,10}
  9: 9,5,6,2,3,4,7,8,1,10
  10: 1,8,4,2,3,5,6,7,9,10
  `;

  const [showDescriptiveStatistic, setShowDescriptiveStatistic] = useState(false);
  const descriptiveData = useSelector((state) => state.data.descriptiveData);
  const location = useLocation();
  const matchData = location.state.matchData;
  const demoBool = location.state.demoBool;
  const [data, setData] = useState([{}]);
  const [bpButton, setbpButton] = useState("primary");
  const [envyButton, setEnvyButton] = useState("outline-primary");
  const [matchButton, setMatchButton] = useState("outline-primary");

  // let showEnvy = false;

  const [showBP, setShowBP] = useState(true);
  const [showEnvy, setShowEnvy] = useState(false);
  const [showMatch, setShowMatch] = useState(false);
  const [envyInfo, setEnvyInfo] = useState(
    "Click on a node to see that agent's envy"
  );
  const [envyVis, setEnvyVis] = useState(
    Array(matchData.metaData[1] + matchData.metaData[2]).fill(true)
  );
  const [selectInfo, setSelectInfo] = useState(
    Array(matchData.metaData[1] + matchData.metaData[2]).fill(false)
  );

  const wrapperSetParentState = useCallback(
    (val) => {
      setEnvyVis(val);
    },
    [setEnvyVis]
  );

  const wrapperSetSelectState = useCallback(
    (val) => {
      setSelectInfo(val);
    },
    [setEnvyVis]
  );

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const handleShowBP = () => {
    setShowBP(!showBP);
    console.log(showBP);
    if (bpButton == "outline-primary") {
      setbpButton("primary");
    } else {
      setbpButton("outline-primary");
    }
  };

  const handleShowEnvy = () => {
    setShowEnvy(!showEnvy);
    console.log(showEnvy);
    if (envyButton == "outline-primary") {
      setEnvyButton("primary");
    } else {
      setEnvyButton("outline-primary");
    }
  };
  const handleProfile = () => {
    setShowProfile(true); 
};

  const handleShowMatch = () => {
    setShowMatch(!showMatch);
    console.log(showMatch);
    if (matchButton == "outline-primary") {
      setMatchButton("primary");
    } else {
      setMatchButton("outline-primary");
    }
    console.log(JSON.stringify(data));
  };

  function generateExplanation() {
    var output = "Loading...";
    if (data.properties?.blockingpairs?.blockingPairCount > 1) {
      output = `This solution contains ${data.properties?.blockingpairs?.blockingPairCount} blocking pairs, meaning that the matching is unstable.`;
    } else if (data.properties?.blockingpairs?.blockingPairCount == 1) {
      output = `This solution contains ${data.properties?.blockingpairs?.blockingPairCount} blocking pair, meaning that the matching is unstable.`;
    } else {
      output = `This solution doesn’t contain any blocking pairs, making this matching stable.`;
    }

    if (data.properties?.envy?.numberViolators > 1) {
      output = output.concat(
        ` ${data.properties?.envy?.numberViolators} agents are envious of other agents, meaning that this matching isn’t fair.`
      );
    } else if (data.properties?.envy?.numberViolators == 1) {
      output = output.concat(
        ` ${data.properties?.envy?.numberViolators} agent is envious of other agents, meaning that this matching isn’t fair.`
      );
    } else {
      output = output.concat(
        ` Additionally, there aren’t any instances of envy between agents, making this matching fair.`
      );
    }

    if (data.properties?.po?.violations > 1) {
      output = output.concat(
        ` Since there are ${data.properties?.po?.violations} Pareto violators in this solution, this matching isn’t efficient.`
      );
    } else if (data.properties?.po?.violations == 1) {
      output = output.concat(
        ` Since there is ${data.properties?.po?.violations} Pareto violator in this solution, this matching isn’t efficient`
      );
    } else {
      output = output.concat(
        ` Since there aren’t any Pareto violations in this solution, this matching is efficient.`
      );
    }
    return output;
  }

  {
    /* <td key={2}>{data.properties?.?.violations}</td>
                            <td key={2}>
                              {data.properties?.envy?.numberViolators}
                            </td>
                            <td key={2}>{data.properties?.po?.violations}</td> */
  }

  // const showEnvyFunc = () => {
  //     console.log('Click happened');
  //     return {showEnvy}
  //   };

  useEffect(() => {
    const fetchData = async () => {
      console.log("this is demobool", demoBool);
      if (demoBool) {
        setData(jsonData);
      } else {
        try {
          console.log("fetch from match-detail");
          const apiData = await API.post(
            "matchxplainAPI",
            "/api/match-detail",
            {
              body: { data: matchData },
            }
          );
          setData(apiData);
          console.log(apiData.properties.envy.violations);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [matchData, demoBool]); // Include demoBool in the dependency array

  return (
    <div>
      <Container>
        <Row>
          <h1>
            Solution Details{" "}
            <Button onClick={goBack} variant="outline-secondary">
              <FaArrowLeft /> Go Back
            </Button>{" "}
          </h1>
          {/* <Col>
                        <Table>
                            <tbody key={"tableBody"}>
                                {matchData.properties.map((object, i) => {
                                    let objectStr = JSON.stringify(object);
                                    let firstKey = Object.keys(object);
                                    let show = Object.values(object);
                                    return (
                                        <tr key={firstKey + "list"}>
                                            <td key={i}>{firstKey}</td>
                                            {show[0] ? (
                                                <td key={firstKey}>
                                                    <FaCheck />
                                                </td>
                                            ) : (
                                                <td key={firstKey + "check"}></td>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                        <p>{matchData.explanation}</p>
                    </Col> */}
          <p></p>
        </Row>
        <Row>
          {typeof matchData === "undefined" ? (
            <p>Loading...</p>
          ) : (
            <Card>
              <Card.Body>
                {/* <h3>Matching</h3> */}
                <div style={{ disply: "flex", justifyContent: "left" }}>
                  <Button
                    style={{ marginRight: "5px" }}
                    onClick={handleShowBP}
                    variant={bpButton}
                  >
                    View Blocking Pairs
                  </Button>
                  <Button
                    style={{ marginRight: "5px" }}
                    onClick={handleShowMatch}
                    variant={matchButton}
                  >
                    View Solution
                  </Button>
                  <Button
                    style={{ marginRight: "5px" }}
                    onClick={handleShowEnvy}
                    variant={envyButton}
                  >
                    View Exchange Cycles
                  </Button>
                  <Button onClick={handleProfile} variant="light"  style={{marginRight: "5px" }}>
                      Review Preference Profile
                  </Button>
                  <ReviewProfile 
                    showProfile={showProfile} 
                    setShowProfile={setShowProfile} 
                    codeContent={codeContent} 
                  />


                    <Button
                      variant="outline-dark"
                      style={{
                        marginRight: "5px" 
                      }}
                      onClick={() => setShowDescriptiveStatistic(true)}
                    >
                      Review Explainable Data
                    </Button>

                    {descriptiveData && showDescriptiveStatistic && (
                      <DescriptiveStatistic
                        show={showDescriptiveStatistic}
                        onHide={() => setShowDescriptiveStatistic(false)}
                        
                        descriptiveData={descriptiveData}
                        
                      />
            )}
                  {/* {(showBP === false) ? (
                                        <p></p>
                                    ) : (
                                            // <p style={{width: '300px'}}><FaRegCircle color='#f56342' />: the agent weakly prefers their blocking pair partner to their current partner <br /> <FaCircle color='#f56342' />: the agent strictly prefers their blocking pair partner to their current partner</p>
                                            <Button style={{marginRight: '5px'}} onClick={handleShowEnvy} variant={envyButton}><FaRegCircle color='#f56342' />: the agent weakly prefers their blocking pair partner to their current partner <br /> <FaCircle color='#f56342' />: the agent strictly prefers their blocking pair partner to their current partner</Button>
                                    )} */}
                </div>
                <br />
                {/* <Button onClick={handleShowEnvy} variant={bpButton}>View Blocking Pairs</Button>
                                <Button onClick={handleShowMatch} variant={matchButton}>View Matching</Button> */}

                <div style={{ width: "100%", height: "600px" }}>
                  {matchData.metaData[2] <= 20 ||
                  matchData.metaData[2] <= 20 ? (
                    <Flow2
                      matchData={matchData}
                      properties={data.properties}
                      bp={showBP}
                      envy={showEnvy}
                      match={showMatch}
                      envyVis={envyVis}
                      setEnvyVis={wrapperSetParentState}
                      envyInfo={envyInfo}
                      setEnvyInfo={setEnvyInfo}
                      selectInfo={selectInfo}
                      setSelectInfo={wrapperSetSelectState}
                    />
                  ) : (
                    <LargeInstanceFlow
                      matchData={matchData}
                      properties={data.properties}
                      bp={showBP}
                      envy={showEnvy}
                      match={showMatch}
                      envyVis={envyVis}
                      setEnvyVis={wrapperSetParentState}
                      envyInfo={envyInfo}
                      setEnvyInfo={setEnvyInfo}
                      selectInfo={selectInfo}
                      setSelectInfo={wrapperSetSelectState}
                    />
                  )}
                </div>
                <br />
                <Row>
                  <Col>
                    <Table>
                      {/* <tbody key={"tableBody"}>
                        {matchData.properties.map((object, i) => {
                          let objectStr = JSON.stringify(object);
                          let firstKey = Object.keys(object);
                          let show = Object.values(object);
                          return (
                            <tr key={firstKey + "list"}>
                              <td key={i}>{firstKey}</td>
                              {show[0] ? (
                                <td key={firstKey}>
                                  <FaCheck />
                                </td>
                              ) : (
                                <td key={firstKey + "check"}></td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody> */}
                      <tbody key={"tableBody"}>
                        <tr key="key">
                          <td key={0}>Unstable Matches</td>
                          {typeof data === "undefined" ? (
                            <p>Loading...</p>
                          ) : (
                            <td key={2}>
                              {
                                data.properties?.blockingpairs
                                  ?.blockingPairCount
                              }
                            </td>
                          )}
                        </tr>
                        <tr key="key">
                          <td key={0}>Envious Agents</td>
                          {typeof data === "undefined" ? (
                            <p>Loading...</p>
                          ) : (
                            <td key={2}>
                              {data.properties?.envy?.numberViolators}
                            </td>
                          )}
                        </tr>
                        <tr key="key">
                          <td key={0}>Exchange Cycles</td>
                          {typeof data === "undefined" ? (
                            <p>Loading...</p>
                          ) : (
                            <td key={2}>
                              {data.properties?.po?.cycleList.length}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col>
                    {/* <p>{matchData.explanation}</p> */}
                    <p>{generateExplanation()}</p>
                  </Col>
                </Row>
                {/* <br />
                <br />
                <br /> */}
                {/* <Container>
                  <Row>
                    <Col lg={6}>
                      <div>
                        <Table>
                          <thead>
                            <tr>
                              <th className="text-center">Agent (proposer)</th>
                              <th className="text-center">Partner</th>
                              <th className="text-center">Preferences</th>
                            </tr>
                          </thead>
                          <tbody>
                            {matchData.matching.men.map((object, i) => {
                              const menPref =
                                matchData.metaData[3].split(/\r?\n/);
                              let objectStr = JSON.stringify(object);
                              let firstKey = Object.keys(object);
                              let show = Object.values(object);
                              return (
                                <tr key={object.id}>
                                  <th
                                    key={"man" + object.id}
                                    className="text-center"
                                  >
                                    {object.id}
                                  </th>
                                  <td
                                    key={"mp" + object.partner}
                                    className="text-center"
                                  >
                                    {object.partner}
                                  </td>
                                  <td className="text-center">{menPref[i]}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <Table>
                        <thead>
                          <tr>
                            <th className="text-center">Agent (proposee)</th>
                            <th className="text-center">Partner</th>
                            <th className="text-center">Preferences</th>
                          </tr>
                        </thead>
                        <tbody>
                          {matchData.matching.women.map((object, i) => {
                            const womenPref =
                              matchData.metaData[4].split(/\r?\n/);
                            let objectStr = JSON.stringify(object);
                            let firstKey = Object.keys(object);
                            let show = Object.values(object);
                            return (
                              <tr key={+object.id}>
                                <th
                                  key={"woman" + object.id}
                                  className="text-center"
                                >
                                  {object.id}
                                </th>
                                <td
                                  key={"wp" + object.partner}
                                  className="text-center"
                                >
                                  {object.partner}
                                </td>
                                <td className="text-center">{womenPref[i]}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container> */}
                {/* <br />
                <h6>Instances of Envy:</h6>
                {typeof data === "undefined" ? (
                  <p>Loading...</p>
                ) : data.properties?.envy?.violations != 0 ? (
                  <div>
                    <p>
                      This matching has {data.properties?.envy?.violations}{" "}
                      instances of <a href="">envy</a>.
                    </p>
                    {data.properties?.envy?.details.map((object, i) => {
                      return <p>{object}</p>;
                    })}
                  </div>
                ) : (
                  <p>
                    This matching is <a href="">envy free</a>.
                  </p>
                )}
                <p></p>
                <h6>Pareto Optimality Violators:</h6>
                <br />
                <h3>About the Algorithm</h3>
                <p>
                  The Stable Marriage Problem states that given N men and N
                  women, where each person has ranked all members of the
                  opposite sex in order of preference, marry the men and women
                  together such that there are no two people of opposite sex who
                  would both rather have each other than their current partners.
                  If there are no such people, all the marriages are “stable...”
                </p> */}
              </Card.Body>
            </Card>
          )}
        </Row>
      </Container>
      <br />
    </div>
  );
}

export default MatchDetail2;
